
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.report-banner {
    background-position: bottom right;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .report-banner {
        padding-bottom: 100px;
        background-size: 45%;
        background-image: url('@/assets/img/report-banner/top-art.svg'),
            url('@/assets/img/report-banner/bg-tall.svg');
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .report-banner {
        padding-bottom: 100px;
        background-size: 30%;
        background-image: url('@/assets/img/report-banner/top-art.svg'),
            url('@/assets/img/report-banner/bg-tall.svg');
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
    .report-banner {
        padding-bottom: 12px;
        background-size: auto;
        background-position: right;
        background-image: url('@/assets/img/report-banner/top-art.svg'),
            url('@/assets/img/report-banner/bg.svg');
    }
}
